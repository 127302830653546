 .login-form {
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;
    
  }
  .login-form-forgot {
    float: right;
  }
   .ant-col-rtl .login-form-forgot {
    float: left;
  }
   .login-form-button {
    width: 100%;
  }
  .bgLogin{

   height:738px;
   width:100%;
   background-size: auto;
  /* background-image: url('../../assets/img/background.jpg'); */
  }