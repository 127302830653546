.modal-productos {
    width: 650px;
    transform-origin: 85px 43px;
    overflow: hidden;
    height: -webkit-fill-available;
  }

  .ant-table.ant-table-middle{
    font-size: 12px;
    height: 290px;
    overflow:auto;

  }
  .ant-table.ant-table-small{
    overflow:auto;
    height: 240px;

  }
  .ant-card-head-title{
    font-size: 14px;
    white-space: normal;
  }

  .ant-table .ant-table-header.ant-table-hide-scrollbar::-webkit-scrollbar { width: 20px !important; }
  
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}